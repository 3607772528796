//#region IMPORT

import { BrowserModule } from "@angular/platform-browser";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { NgxEchartsModule } from "ngx-echarts";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { MatSliderModule } from "@angular/material/slider";;
import { MatInputModule } from "@angular/material/input";
import { ServiceWorkerModule } from "@angular/service-worker";
import { NgSelectModule } from "@ng-select/ng-select";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { provideEnvironmentNgxMask, IConfig, NgxMaskDirective, NgxMaskPipe } from "ngx-mask";
import { environment } from "src/environments/environment";
import { CodeofethicscheckerlistComponent } from "./components/gcgonline/codeofethics/codeofethicscheckerlist/codeofethicscheckerlist.component";
import { CodeofethicssignerlistComponent } from "./components/gcgonline/codeofethics/codeofethicssignerlist/codeofethicssignerlist.component";
import { CodeofethicssignerdetailComponent } from "./components/gcgonline/codeofethics/codeofethicssignerdetail/codeofethicssignerdetail.component";
import { CodeofethicscheckerdetailComponent } from "./components/gcgonline/codeofethics/codeofethicscheckerdetail/codeofethicscheckerdetail.component";
import { AnnualdisclosurecheckerlistComponent } from "./components/gcgonline/annualdisclosure/annualdisclosurecheckerlist/annualdisclosurecheckerlist.component";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./components/root/app.component";
import { PipeModule } from "./functions/pipes/pipe.module";
import { DashboardComponent } from "./components/dashboards/dashboard/dashboard.component";
import { TableControlIncludeComponent } from "./components/includes/tablecontrol.include/tablecontrol.include.component";
import { HomeComponent } from "./components/home/home.component";
import { SigninComponent } from "./components/signin/signin.component";
import { TicketdetailComponent } from "./components/ticket/ticketdetail/ticketdetail.component";
import { TicketlistinboxComponent } from "./components/ticket/ticketlistinbox/ticketlistinbox.component";
import { TicketlistoutboxComponent } from "./components/ticket/ticketlistoutbox/ticketlistoutbox.component";
import { TicketSatisfactionComponent } from "./components/ticket/ticketsatisfaction/ticketsatisfaction.component";
import { DownloadComponent } from "./components/download/download.component";
import { PwaService } from "./services/pwa.service";
import { ApprovalReimbursementListComponent } from "./components/approvalreimbursement/approvalreimbursementlist/approvalreimbursementlist.component";
import { DentalreimbursementdetailComponent } from "./components/approvalreimbursement/dentalreimbursementdetail/dentalreimbursementdetail.component";
import { MaternityreimbursementdetailComponent } from "./components/approvalreimbursement/maternityreimbursementdetail/maternityreimbursementdetail.component";
import { OpticreimbursementdetailComponent } from "./components/approvalreimbursement/opticreimbursementdetail/opticreimbursementdetail.component";
import { PostmaternityreimbursementdetailComponent } from "./components/approvalreimbursement/postmaternityreimbursementdetail/postmaternityreimbursementdetail.component";
import { PrematernityreimbursementdetailComponent } from "./components/approvalreimbursement/prematernityreimbursementdetail/prematernityreimbursementdetail.component";
import { AssessmentlistComponent } from "./components/rcsa/assessment/assessmentlist/assessmentlist.component";
import { AssessmentdetailComponent } from "./components/rcsa/assessment/assessmentdetail/assessmentdetail.component";
import { ApprovallistComponent } from "./components/rcsa/approval/approvallist/approvallist.component";
import { ApprovaldetailComponent } from "./components/rcsa/approval/approvaldetail/approvaldetail.component";
import { ReportlistComponent } from "./components/rcsa/report/reportlist/reportlist.component";
import { ReportdetailComponent } from "./components/rcsa/report/reportdetail/reportdetail.component";
import { RiskissuerequestlistComponent } from "./components/rcsa/riskissuerequest/riskissuerequestlist/riskissuerequestlist.component";
import { RiskissuerequestdetailComponent } from "./components/rcsa/riskissuerequest/riskissuerequestdetail/riskissuerequestdetail.component";
import { SubmissionInsertComponent } from "./components/digitalization/submissions/submissioninsert/submissioninsert.component";
import { SubmissionListComponent } from "./components/digitalization/submissions/submissionlist/submissionlist.component";
import { SubmissionDetailComponent } from "./components/digitalization/submissions/submissiondetail/submissiondetail.component";
import { DashboardrasdetailComponent } from "./components/ras/dashboardras/dashboardrasdetail/dashboardrasdetail.component";
import { DashboardraslistComponent } from "./components/ras/dashboardras/dashboardraslist/dashboardraslist.component";
import { RealizationapprovaldetailComponent } from "./components/ras/realization/realizationapprovaldetail/realizationapprovaldetail.component";
import { RealizationapprovallistComponent } from "./components/ras/realization/realizationapprovallist/realizationapprovallist.component";
import { RealizationlistComponent } from "./components/ras/realization/realizationlist/realizationlist.component";
import { RealizationmonitoringdetailComponent } from "./components/ras/realization/realizationmonitoringdetail/realizationmonitoringdetail.component";
import { RealizationmonitoringlistComponent } from "./components/ras/realization/realizationmonitoringlist/realizationmonitoringlist.component";
import { RealizationparameterdetailComponent } from "./components/ras/realization/realizationparameterdetail/realizationparameterdetail.component";
import { RealizationsignerapprovaldetailComponent } from "./components/ras/realization/realizationsignerapprovaldetail/realizationsignerapprovaldetail.component";
import { RealizationsignerapprovallistComponent } from "./components/ras/realization/realizationsignerapprovallist/realizationsignerapprovallist.component";
import { CheckDevToolsDirective } from "./components/bases/basecheckdevtools.directive";
import { EmateraicheckersignerlistComponent } from "./components/ematerai/emateraicheckersignerlist/emateraicheckersignerlist.component";
import { EmateraicheckersignerdetailComponent } from "./components/ematerai/emateraicheckersignerdetail/emateraicheckersignerdetail.component";
import { EmateraimakerlistComponent } from "./components/ematerai/emateraimakerlist/emateraimakerlist.component";
import { EmateraimakerdetailComponent } from "./components/ematerai/emateraimakerdetail/emateraimakerdetail.component";
import { TicketReportComponent } from "./components/ticket/ticketreport/ticketreport/ticketreport.component";
import { TicketReportSatisfactionComponent } from "./components/ticket/ticketreport/ticketreportsatisfaction/ticketreportsatisfaction.component";
import { AnnualdisclosuredetailComponent } from "./components/gcgonline/annualdisclosure/annualdisclosuredetail/annualdisclosuredetail.component";
import { AnnualdisclosureinsertComponent } from "./components/gcgonline/annualdisclosure/annualdisclosureinsert/annualdisclosureinsert.component";
import { AnnualdisclosurelistComponent } from "./components/gcgonline/annualdisclosure/annualdisclosurelist/annualdisclosurelist.component";
import { AnnualdisclosuresignerdetailComponent } from "./components/gcgonline/annualdisclosure/annualdisclosuresignerdetail/annualdisclosuresignerdetail.component";
import { AnnualdisclosuresignerlistComponent } from "./components/gcgonline/annualdisclosure/annualdisclosuresignerlist/annualdisclosuresignerlist.component";
import { AnnualdisclosurecheckerdetailComponent } from "./components/gcgonline/annualdisclosure/annualdisclosurecheckerdetail/annualdisclosurecheckerdetail.component";
import { CodeofethicsdetailComponent } from "./components/gcgonline/codeofethics/codeofethicsdetail/codeofethicsdetail.component";
import { CodeofethicsinsertComponent } from "./components/gcgonline/codeofethics/codeofethicsinsert/codeofethicsinsert.component";
import { CodeofethicslistComponent } from "./components/gcgonline/codeofethics/codeofethicslist/codeofethicslist.component";
import { GratificationdetailComponent } from "./components/gcgonline/gratification/gratificationdetail/gratificationdetail.component";
import { GratificationlistComponent } from "./components/gcgonline/gratification/gratificationlist/gratificationlist.component";
import { IntegritypactdetailComponent } from "./components/gcgonline/integritypact/integritypactdetail/integritypactdetail.component";
import { IntegritypactinsertComponent } from "./components/gcgonline/integritypact/integritypactinsert/integritypactinsert.component";
import { IntegritypactlistComponent } from "./components/gcgonline/integritypact/integritypactlist/integritypactlist.component";
import { ProfilegcgonlineComponent } from "./components/gcgonline/profilegcgonline/profilegcgonline.component";
import { CustomerdataComponent } from "./components/apu-ppt/customerdata/customerdata.component";
import { RiskbasedassessmentComponent } from "./components/apu-ppt/riskbasedassessment/riskbasedassessment.component";
import { TransactionlistComponent } from "./components/apu-ppt/transaction/transactionlist/transactionlist.component";
import { TransactiondetailComponent } from "./components/apu-ppt/transaction/transactiondetail/transactiondetail.component";
import { TaxRegulationListComponent } from "./components/brinstax/taxregulation/taxregulationlist/taxregulationlist.component";
import { TaxRegulationDetailComponent } from "./components/brinstax/taxregulation/taxregulationdetail/taxregulationdetail.component";
import { ProjectmanagementlistComponent } from "./components/brinspro/projectmanagement/projectmanagementlist/projectmanagementlist.component";
import { ProjectmanagementdetailComponent } from "./components/brinspro/projectmanagement/projectmanagementdetail/projectmanagementdetail.component";
import { ProjectmanagementdashboardComponent } from "./components/brinspro/projectmanagementdashboard/projectmanagementdashboard.component";
import { ProjectmanagementdocumentdetailComponent } from "./components/brinspro/projectmanagementdocument/projectmanagementdocumentdetail/projectmanagementdocumentdetail.component";
import { ProjectmanagementdocumentlistComponent } from "./components/brinspro/projectmanagementdocument/projectmanagementdocumentlist/projectmanagementdocumentlist.component";
import { BarchartIncludeComponent } from "./components/includes/barchart.include/barchart.include.component";
import { GroupbarchartIncludeComponent } from "./components/includes/groupbarchart.include/groupbarchart.include.component";
import { PiechartIncludeComponent } from "./components/includes/piechart.include/piechart.include.component";
import { KeyperformanceapprovalregionalofficeComponent } from "./components/brinsprime/keyperformanceapprovalregionaloffice/keyperformanceapprovalregionaloffice.component";
import { KeyperformancemonitoringregionalofficeComponent } from "./components/brinsprime/keyperformancemonitoringregionaloffice/keyperformancemonitoringregionaloffice.component";

//#endregion


//#region MODULE

const initializer = (pwaService: PwaService) => () => pwaService.setupInstaller();
const maskConfig: Partial<IConfig> = {
	validation: false,
};

@NgModule
(
	{
		declarations:
		[
			AppComponent,
			SigninComponent,
			HomeComponent,
			DashboardComponent,
			TableControlIncludeComponent,
			TicketdetailComponent,
			TicketlistinboxComponent,
			TicketlistoutboxComponent,
			TicketSatisfactionComponent,
			TicketReportComponent,
			TicketReportSatisfactionComponent,
			DownloadComponent,
			ApprovalReimbursementListComponent,
			DentalreimbursementdetailComponent,
			OpticreimbursementdetailComponent,
			PostmaternityreimbursementdetailComponent,
			PrematernityreimbursementdetailComponent,
			MaternityreimbursementdetailComponent,
			AssessmentlistComponent,
			AssessmentdetailComponent,
			ApprovallistComponent,
			ApprovaldetailComponent,
			ReportlistComponent,
			ReportdetailComponent,
			RiskissuerequestlistComponent,
			RiskissuerequestdetailComponent,
			SubmissionInsertComponent,
			SubmissionListComponent,
			SubmissionDetailComponent,
			CheckDevToolsDirective,
			DashboardraslistComponent,
			DashboardrasdetailComponent,
			RealizationlistComponent,
			RealizationapprovallistComponent,
			RealizationapprovaldetailComponent,
			RealizationparameterdetailComponent,
			RealizationsignerapprovallistComponent,
			RealizationsignerapprovaldetailComponent,
			RealizationmonitoringlistComponent,
			RealizationmonitoringdetailComponent,
			EmateraicheckersignerlistComponent,
			EmateraicheckersignerdetailComponent,
			EmateraimakerlistComponent,
			EmateraimakerdetailComponent,
			ProfilegcgonlineComponent,
			GratificationlistComponent,
			GratificationdetailComponent,
			IntegritypactlistComponent,
			IntegritypactinsertComponent,
			IntegritypactdetailComponent,
			CodeofethicslistComponent,
			CodeofethicsinsertComponent,
			CodeofethicsdetailComponent,
			CodeofethicscheckerlistComponent,
			CodeofethicssignerlistComponent,
			CodeofethicssignerdetailComponent,
			CodeofethicscheckerdetailComponent,
			AnnualdisclosurelistComponent,
			AnnualdisclosureinsertComponent,
			AnnualdisclosuredetailComponent,
			AnnualdisclosurecheckerlistComponent,
			AnnualdisclosurecheckerdetailComponent,
			AnnualdisclosuresignerlistComponent,
			AnnualdisclosuresignerdetailComponent,
			CustomerdataComponent,
			RiskbasedassessmentComponent,
			TransactionlistComponent,
			TransactiondetailComponent,
			TaxRegulationListComponent,
			TaxRegulationDetailComponent,
			ProjectmanagementlistComponent,
			ProjectmanagementdetailComponent,
			ProjectmanagementdashboardComponent,
			ProjectmanagementdocumentdetailComponent,
			ProjectmanagementdocumentlistComponent,
			BarchartIncludeComponent,
			GroupbarchartIncludeComponent,
			PiechartIncludeComponent,
			KeyperformanceapprovalregionalofficeComponent,
			KeyperformancemonitoringregionalofficeComponent
		],
		imports:
		[
			BrowserModule,
			AppRoutingModule,
			FormsModule,
			HttpClientModule,
			PipeModule,
			BrowserAnimationsModule,
			NgxChartsModule,
			MatNativeDateModule,
			MatInputModule,
			MatSliderModule,
			MatDatepickerModule,
			ReactiveFormsModule,
			NgSelectModule,
			NgxMaskDirective,
			NgxMaskPipe,
			PdfViewerModule,
			DragDropModule,
			NgxEchartsModule.forRoot
			(
				{
					echarts: () => import("echarts")
				}
			),
			ServiceWorkerModule.register("ngsw-worker.js", {
				enabled: environment.serviceWorker,
				// Register the ServiceWorker as soon as the app is stable
				// or after 30 seconds (whichever comes first).
				registrationStrategy: "registerWhenStable:30000"
			})
		],
		providers:
		[
			{
				provide: APP_INITIALIZER, useFactory: initializer, deps: [PwaService], multi: true
			},
			provideEnvironmentNgxMask(maskConfig)
		],
		bootstrap: [AppComponent]
	}
)

//#endregion


//#region CLASS

export class AppModule { }

//#endregion