//#region IMPORT

import { Router } from "@angular/router";
import { PartialObserver } from "rxjs";
import { RegionalOfficeModel } from "src/app/models/regionalofficemodel";
import { UserModel } from "src/app/models/user.model";
import { SessionService } from "src/app/services/session.service";
import { StringConstant } from "src/app/constants/string.constant";
import { ENUM_CATEGORY_TYPE, ENUM_DOCUMENT_TYPE } from "src/app/constants/enum.constant";
import { EnumGeneralModel } from "src/app/models/enumgeneral.model";
import { ConvertSeparateEnumSpace } from "src/app/functions/pipes/converter.pipe";
import { BasePublicComponent } from "./basepublic.component";

//#endregion


//#region CLASS

export class BaseAuthourizeComponent extends BasePublicComponent
{
	//#region DECLARATION

	public _modelUserSignIn: UserModel;
	public _modelRegionalOfficeSignIn: RegionalOfficeModel;
	public _modelNull: any = null;

	//#endregion


	//#region CONSTRUCTOR

	constructor(serviceSession: SessionService, router: Router)
	{
		super(serviceSession, router);

		this._modelUserSignIn = new UserModel();
		this._modelRegionalOfficeSignIn = new RegionalOfficeModel();

		const observerPartialModelUserSignIn: PartialObserver<UserModel> =
		{
			next: (modelUser: UserModel) => { this._modelUserSignIn = modelUser; },
			error: () => { },
			complete: () => { }
		};

		const observerPartialModelRegionalOfficeSignIn: PartialObserver<RegionalOfficeModel> =
		{
			next: (modelRegionalOfficeModel: RegionalOfficeModel) => { this._modelRegionalOfficeSignIn = modelRegionalOfficeModel; },
			error: () => { },
			complete: () => { }
		};

		this._serviceSession._modelUserSignIn.subscribe(observerPartialModelUserSignIn);
		this._serviceSession._modelRegionalOfficeSignIn.subscribe(observerPartialModelRegionalOfficeSignIn);
	}

	//#endregion


	//#region FUNCTION

	protected signOut(): void
	{
		this._serviceSession.clearKey();
		this._serviceSession.clearSession();
		this._serviceSession.clearSessionQuery();

		this._router.navigate(["signin"]);
	}

	protected signOutDownload(stringURL: string): void
	{
		this._serviceSession.clearKey();
		this._serviceSession.clearSession();
		this._serviceSession.clearSessionQuery();

		this._router.navigate(["signin", stringURL]);
	}

	//#endregion


	//#region CHECK

	protected checkURL(stringURLPath: string): boolean
	{
		return this._router.url.indexOf(stringURLPath) > -1;
	}

	//#endregion

	//#region GET LIST ENUM

	public getListEnumByName(stringEnumName: string): Array<EnumGeneralModel>
	{
		let enumSelected: any;

		if(stringEnumName === StringConstant.STRING_ENUM_DOCUMENT_TYPE)
		{
			enumSelected = ENUM_DOCUMENT_TYPE;
		}
		else if(stringEnumName === StringConstant.STRING_ENUM_CATEGORY_TYPE)
		{
			enumSelected = ENUM_CATEGORY_TYPE;
		}
		if(enumSelected)
		{
			const arrayObjectEnumGeneral = Object.keys(enumSelected)
				.filter(
					stringKey => !Number.isNaN(Number(enumSelected[stringKey])))
				.flatMap(
					stringKey => (
						[
							{ ID: enumSelected[stringKey], Name: stringKey }
						]
					)
				);

			const arrayModelEnumGeneral: Array<EnumGeneralModel> = [];
			arrayObjectEnumGeneral.forEach(
				objectEnumGeneralLoop =>
				{
					const pipeConvertSeparateEnumSpace: ConvertSeparateEnumSpace = new ConvertSeparateEnumSpace();
					const modelEnumGeneral: EnumGeneralModel = new EnumGeneralModel();
					modelEnumGeneral.ID = objectEnumGeneralLoop.ID;
					modelEnumGeneral.Name = pipeConvertSeparateEnumSpace.transform(objectEnumGeneralLoop.Name);

					arrayModelEnumGeneral.push(modelEnumGeneral);
				}
			);

			return arrayModelEnumGeneral;
		}
		else
		{
			return [];
		}

	}

	//#endregion
}

//#endregion